@let features = (features$ | async) ?? [];
@let userSwitchEnabled = (userSwitchEnabled$ | async) ?? false;

<div class="text-right pr-8">
    <ul class="inline-flex flex-col items-end gap-y-4">
        @for (item of menu; track $index) {
            <li>
                <a [routerLink]="[item.route]" routerLinkActive="text-primary active">
                    {{ item.name | translate }}
                </a>
            </li>
        }

        <li class="pt-8">
            <a href="#" (click)="onProfileClick($event)" translate>btn.profile</a>
        </li>

        @if (features.includes('shopping-lists')) {
            <li>
                <a routerLink="/user-profile/shopping-lists" translate>shopping-lists.list.title</a>
            </li>
        }

        <li>
            @if (userSwitchEnabled) {
                <a href="#" (click)="onUserSwitchClick($event)" translate>btn.switch-user</a>
            } @else {
                <a href="#" (click)="onLogoutClick($event)" translate>btn.logout</a>
            }
        </li>
    </ul>
</div>
