import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
    private readonly router = inject(Router);

    onLogoutClick() {
        this.router.navigate(['/logout'], {
            skipLocationChange: true,
        });
    }
}
