import { Routes, Route } from '@angular/router';
import { ShellSalesComponent } from '../components/shell/shell.component';
import { salesGuard } from '@app/shells/guards/sales.guard';
import { userCleanup } from '@app/store/user';

export class ShellSales {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            canActivate: [salesGuard()],
            canDeactivate: [userCleanup()],
            component: ShellSalesComponent,
            children: routes,
        };
    }
}
