import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionModule } from '@app/modules/version';
import { IconsModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CartModule } from '@app/modules/cart';
import { PricePipeModule } from '@app/shared/pipes';

import { FooterComponent } from './components/footer/footer.component';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { SocialIconsComponent } from './components/social-icons/social-icons.component';
import { LogoComponent } from './components/logo/logo.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { SwitchUserComponent } from './components/switch-user/switch-user.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { CartSummaryMobileComponent } from './components/cart-summary-mobile/cart-summary-mobile.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfilePopoverComponent } from './components/profile-popover/profile-popover.component';
import { ProfilePopoverTriggerComponent } from './components/profile-popover-trigger/profile-popover-trigger.component';

@NgModule({
    imports: [CommonModule, VersionModule, IconsModule, TranslateModule, RouterModule, CartModule, PricePipeModule],
    declarations: [
        FooterComponent,
        AccountMenuComponent,
        SocialIconsComponent,
        LogoComponent,
        LogoutComponent,
        ProfileComponent,
        ProfilePopoverTriggerComponent,
        ProfilePopoverComponent,
        MenuComponent,
        MenuMobileComponent,
        SwitchUserComponent,
        CartSummaryComponent,
        CartSummaryMobileComponent,
    ],
    exports: [
        FooterComponent,
        AccountMenuComponent,
        LogoComponent,
        LogoutComponent,
        ProfileComponent,
        ProfilePopoverComponent,
        MenuComponent,
        MenuMobileComponent,
        SwitchUserComponent,
        CartSummaryComponent,
        CartSummaryMobileComponent,
    ],
})
export class ShellSharedModule {}
