import { Routes, Route } from '@angular/router';
import { ShellAuthComponent } from '../components/shell/shell.component';

export class ShellAuth {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellAuthComponent,
            children: routes,
        };
    }
}
