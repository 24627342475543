import { Component, HostBinding } from '@angular/core';
import { OpenCloseSpinner } from '../../animations/spinner.animation';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    animations: [OpenCloseSpinner],
    standalone: true,
})
export class SpinnerComponent {
    @HostBinding('@openCloseSpinner') get(): void {}
}
