import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Grow } from '@app/shared/animations';
import { Message, MessageService } from '@corelabs/angular-messages';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
})
export class MessagesComponent {
    private readonly messageService = inject(MessageService);

    messages$ = this.messageService.messages$;

    trackByUuid(index: number, message: Message) {
        return message.id;
    }
}
