import { CanDeactivateFn } from '@angular/router';
import { AddressAlertService } from '../services/address-alert.service';
import { inject } from '@angular/core';

export function cleanupAddressAlert<T>(): CanDeactivateFn<T> {
    return () => {
        const addressAlertService = inject(AddressAlertService);
        addressAlertService.clear();
        return true;
    };
}
