import { CanActivateFn } from '@angular/router';
import { AddressAlertService } from '../services/address-alert.service';
import { inject } from '@angular/core';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { ShippingAddressesFacade } from '@app/store/shipping-addresses';
import { take, tap } from 'rxjs';

export function addressAlertGuard(): CanActivateFn {
    return () => {
        const addressAlertService = inject(AddressAlertService);
        const messageService = inject(MessageService);
        const translateService = inject(TranslateService);
        const shippingAddressesFacade = inject(ShippingAddressesFacade);

        shippingAddressesFacade.addresses$
            .pipe(
                take(1),
                tap((addresses) => {
                    if (!addressAlertService.get() && addresses.length > 1) {
                        messageService.clear();
                        messageService.add(translateService.instant('select-address-info'), 'info');
                        addressAlertService.set();
                    }
                }),
            )
            .subscribe();

        return true;
    };
}
