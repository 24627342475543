import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
    readonly logo = `${options.imagesPath}${options.logo}`;
}
