<div class="min-h-screen grid">
    <section class="w-full overflow-auto h-full flex justify-center items-center">
        <div [style.background-image]="'url(' + bgImage + ')'" class="p-6 w-[100vw] h-[100vh] bg-center bg-cover">
            <div class="box flex flex-col">
                <app-header></app-header>
                <div class="flex flex-col content-center justify-center grow">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </section>
</div>
