<a class="flex items-center gap-2" routerLink="cart">
    <app-icon name="mdi:cart-outline" class="icon-6 icon-gray-700"></app-icon>

    <div>
        <div class="text-xs leading-5 font-normal text-[#808080] whitespace-nowrap">{{ 'cart-name' | translate }}</div>

        <div *ngIf="(features$ | async)?.includes('show-prices')" class="text-xs leading-6 tracking-[0.0175rem] font-semibold whitespace-nowrap">
            {{ cart$ | async | total | price }}
        </div>
    </div>
</a>
