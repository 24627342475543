<div class="text-right pr-6 mb-10">
    <a class="inline-flex items-center" routerLink="cart">
        <span class="text-xs leading-none text-white font-semibold whitespace-nowrap">{{ cart$ | async | total | price }}</span>

        <button type="button" class="cart">
            <app-icon name="mdi:cart-outline" class="icon-5 icon-gray-700"></app-icon>
            <span @ShowHide *ngIf="cartProcessing$ | async" class="loader"></span>
        </button>
    </a>
</div>
