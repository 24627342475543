import { Component } from '@angular/core';
import { footerEnvironment } from '@footer';

@Component({
    selector: 'app-social-icons',
    templateUrl: './social-icons.component.html',
    styleUrls: ['./social-icons.component.scss'],
})
export class SocialIconsComponent {
    readonly links = footerEnvironment.socialLinks;
}
