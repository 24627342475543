import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env';
import { build } from 'build';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionComponent {
    environment = environment;
    build = build;
}
