import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Message, MessageService } from '@corelabs/angular-messages';
import { Grow } from '@app/shared/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    animations: [Grow],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
    @Input({ required: true }) message!: Message;

    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    progress$!: Observable<number>;

    ngOnInit() {
        this.messageService.show(this.message.id);
        this.progress$ = this.messageService.progress$(this.message.id).pipe(
            map((progress) => progress || 0),
            map((progress) => 62.8 * (Math.floor(progress * 100) / 100)),
        );
    }

    get text(): string {
        if (this.message.message) {
            return this.message.message;
        }
        if (this.message.key) {
            return this.translateService.instant(this.message.key);
        }
        return '';
    }

    onMessageCloseClick() {
        this.messageService.hide(this.message.id);
    }

    onMouseEnter() {
        this.messageService.onMouseEnter(this.message.id);
    }

    onMouseLeave() {
        this.messageService.onMouseLeave(this.message.id);
    }
}
