import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ShippingAddressesFacade } from '@app/store/shipping-addresses';
import { Subscription, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { AddressPipe } from '../../pipes/address.pipe';
import { NonNullableFormBuilder } from '@angular/forms';

@Component({
    selector: 'app-shipping-addresses',
    templateUrl: './shipping-addresses.component.html',
    styleUrls: ['./shipping-addresses.component.scss'],
    providers: [AddressPipe],
})
export class ShippingAddressesComponent implements OnInit, OnDestroy {
    private readonly fb = inject(NonNullableFormBuilder);
    private readonly addressPipe = inject(AddressPipe);
    private readonly shippingAddressesFacade = inject(ShippingAddressesFacade);

    readonly form = this.fb.group({
        code: this.fb.control<string | null>(null),
    });

    addressesOptions$ = this.shippingAddressesFacade.addresses$.pipe(
        map((addresses) =>
            addresses.map((address) => ({
                name: this.addressPipe.transform(address),
                value: address.code,
            })),
        ),
    );

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            this.shippingAddressesFacade.current$.pipe(tap((address) => this.form.controls.code.patchValue(address.code, { emitEvent: false }))).subscribe(),
        );

        this.subscriptions$.add(
            this.form.valueChanges
                .pipe(
                    map(() => this.form.controls.code.getRawValue()),
                    filter((code): code is string => code !== null),
                    distinctUntilChanged(),
                    tap((code) => this.shippingAddressesFacade.setShippingAddresses({ code })),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }
}
