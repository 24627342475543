import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductFiltersFacade } from '../facades/product-filters.facade';

export const productFiltersCleanup: CanDeactivateFn<unknown> = () => {
    const productFiltersFacade = inject(ProductFiltersFacade);

    productFiltersFacade.clear();
    return true;
};
