import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserSwitchService } from '@app/modules/user-switch';

@Component({
    selector: 'app-switch-user',
    templateUrl: './switch-user.component.html',
    styleUrls: ['./switch-user.component.scss'],
})
export class SwitchUserComponent {
    private readonly router = inject(Router);
    private readonly userSwitchService = inject(UserSwitchService);

    onUserSwitchClick() {
        this.userSwitchService.reset();
        this.router.navigate(['/customers']);
    }
}
