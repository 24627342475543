import { HttpClient } from '@angular/common/http';
import { HttpService } from '@corelabs/angular-core';
import { Injectable } from '@angular/core';
import { UserSwitchInterceptor } from '../interceptors/user-switch.interceptor';
import { CacheInterceptor } from '../interceptors/cache.interceptor';
import { ShippingAddressSwitchInterceptor } from '../interceptors/shipping-address-switch.interceptor';

declare module '@angular/common/http' {
    export interface HttpClient {
        addUserSwitch(): HttpClient;
        addShippingAddressSwitch(): HttpClient;
        addAllSwitches(): HttpClient;
        addResponseCaching(): HttpClient;
    }
}

@Injectable({
    providedIn: 'root',
})
export class HttpCoreService extends HttpService {
    override addUserSwitch(): HttpClient {
        return this.addInterceptor(UserSwitchInterceptor);
    }

    override addShippingAddressSwitch(): HttpClient {
        return this.addInterceptor(ShippingAddressSwitchInterceptor);
    }

    override addAllSwitches(): HttpClient {
        return this.addInterceptor(UserSwitchInterceptor).addInterceptor(ShippingAddressSwitchInterceptor);
    }

    override addResponseCaching(): HttpClient {
        return this.addInterceptor(CacheInterceptor);
    }
}
