import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { map, take } from 'rxjs';
import { RoleService } from '@app/shared/services/role.service';

export function salesGuard(): CanActivateFn {
    return () => {
        const router = inject(Router);
        const roleService = inject(RoleService);

        return roleService.asSalesperson$.pipe(
            map((hasAccess) => (hasAccess ? true : router.createUrlTree(['/dashboard']))),
            take(1),
        );
    };
}
