<div class="absolute bg-white shadow rounded-2xl border flex flex-col right-0 w-max p-6 gap-5 mt-4">
    <ng-container *ngIf="features$ | async as features">
        <a
            *ngIf="features.includes('shopping-lists')"
            [routerLink]="type === 'salesperson' ? '/profile/shopping-lists' : '/user-profile/shopping-lists'"
            class="flex items-center"
        >
            <app-icon name="material-symbols:favorite-outline" class="icon-[1.375rem]" />

            <span> {{ 'header.profile.popover.shopping-lists' | translate }} </span>
        </a>

        <a [routerLink]="type === 'salesperson' ? '/profile/change-password' : '/user-profile/change-password'" class="flex items-center">
            <app-icon name="material-symbols:settings-outline" class="icon-[1.375rem]" />

            <span> {{ 'header.profile.popover.account-settings' | translate }}</span>
        </a>
    </ng-container>
</div>
