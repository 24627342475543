import { Component, Input, inject } from '@angular/core';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
    private readonly userFacade = inject(UserFacade);

    @Input() type: 'user' | 'salesperson' = 'user';

    readonly features$ = this.userFacade.features$;
}
