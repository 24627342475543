import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellAuthComponent {
    readonly bgImage = `${options.imagesPath}${options.loginBackground}`;
}
