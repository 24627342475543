import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorage } from '@app/shared/classes';
import { constants } from '@const';

@Injectable({
    providedIn: 'root',
})
export class UserSwitchInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const key = constants.userSwitch.keys.header;
        const value = LocalStorage.read(constants.userSwitch.keys.localStorage);

        if (value !== null) {
            const modifiedReq = req.clone({
                headers: req.headers.set(key, value),
            });
            return next.handle(modifiedReq);
        }

        return next.handle(req);
    }
}
