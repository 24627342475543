import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { MessageService } from '@corelabs/angular-messages';

@Injectable({
    providedIn: 'root',
})
export class ErrorMessageInterceptor implements HttpInterceptor {
    private readonly messageService = inject(MessageService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 400) {
                    const dataMessage = errorResponse.error?.data?.message;
                    const file = errorResponse.error?.data?.file;

                    if (dataMessage && file === '/var/www/src/Service/Eip/Eip.php') {
                        this.messageService.add(dataMessage, 'danger');
                    }
                }

                throw errorResponse;
            }),
        );
    }
}
