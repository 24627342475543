import { CanDeactivateFn } from '@angular/router';
import { BlockadesService } from '../services/blockades.service';
import { inject } from '@angular/core';

export function cleanupBlockades<T>(): CanDeactivateFn<T> {
    return () => {
        const blockadesService = inject(BlockadesService);
        blockadesService.clear();
        return true;
    };
}
