/**
 * #DEPRECATED
 */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent {
    @Input()
    type: 'user' | 'salesperson' = 'user';
}
