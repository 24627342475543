import { Component, inject, Input } from '@angular/core';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-profile-popover',
    templateUrl: './profile-popover.component.html',
    styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent {
    @Input() type: 'user' | 'salesperson' = 'user';

    private readonly userFacade = inject(UserFacade);

    readonly features$ = this.userFacade.features$;
}
