import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subscription, tap } from 'rxjs';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-profile-popover-trigger',
    templateUrl: './profile-popover-trigger.component.html',
    styleUrls: ['./profile-popover-trigger.component.scss'],
    animations: [Grow],
})
export class ProfilePopoverTriggerComponent implements OnInit, OnDestroy {
    private readonly router = inject(Router);

    @Input() type: 'user' | 'salesperson' = 'user';
    @ViewChild('container') container!: ElementRef<HTMLDivElement>;

    private readonly subscriptions$ = new Subscription();
    readonly open$ = new BehaviorSubject(false);

    ngOnInit() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter(() => this.open$.value),
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    tap(() => this.open$.next(false)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    togglePopover() {
        this.open$.next(!this.open$.value);
    }

    onFocusOut(event: FocusEvent) {
        const target = <HTMLElement>event.relatedTarget;
        if (this.container.nativeElement.contains(target) === false) {
            this.open$.next(false);
        }
    }
}
